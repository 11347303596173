<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            name="period"
            label="지급기간"
            defaultStart="-6M"
            defaultEnd="6M"
            v-model="period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            :plantCd="searchParam.plantCd"
            :isFirstValue="false"
            label="지급부서"
            name="giveDeptCd"
            v-model="searchParam.giveDeptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field
            :plantCd="searchParam.plantCd"
            label="불출담당자"
            name="giveUserId"
            v-model="searchParam.giveUserId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field
            :plantCd="searchParam.plantCd"
            label="수령인"
            name="recipientId"
            v-model="searchParam.recipientId"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="보호구 지급현황"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
    >
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-gear-status',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'recipientName',
            field: 'recipientName',
            label: '수령인',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'giveDate',
            field: 'giveDate',
            label: '지급일',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'safetyTypeName',
            field: 'safetyTypeName',
            label: '보호구종류',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            align: 'center',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'giveCount',
            field: 'giveCount',
            label: '지급수량',
            style: 'width:120px',
            align: 'right',
            sortable: true,
          },
          {
            name: 'giveDeptName',
            field: 'giveDeptName',
            label: '지급부서',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'giveUserName',
            field: 'giveUserName',
            label: '불출담당자',
            style: 'width:180px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        giveDeptCd: '',
        giveUserId: '',
        recipientId: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.sas.give.status.url;
      this.getList();
    },
    /* eslint-disable no-unused-vars */
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  },
};
</script>
